<!-- 
选地址  根据行政区域拿取 商品价格和商品配送方式（重算金额）

商品可使用积分 = 商品数量* 商品积分价（fPointPrice） || fEndBalance（用户积分余量 ）  这两个哪个值小用哪个。
0  <= 用户输入积分 <=  商品可使用积分
积分换算率 pointRMBPrice
积分抵扣金额  积分换算率 * 用户输入积分

优惠券使用哪个就是多少优惠券抵扣金额
优惠券选择 ，当商品价格（单价*数量）

选择配送方式为承运商后 开始算运费
自提和仓库代送 运费为 0 
用户选择是否用运输发票 来判断使用含税不含税的运费价格。
fFreightPrice（不含税）、fFreightPriceTax（含税）
fFreightPrice * fPlanAmount = 最终商品运费 
 -->
<template>
	<div :class="themeClass" class="min-width1100 max-width1100 margin-lr-auto"
		style="width: 1110px;margin-top: 20px;background-color: #FFFFFF;" v-loading="laoding">
		<!-- 面包屑 -->
		<!-- <breadcrumb></breadcrumb> -->
		<!-- 面包屑END -->
		<div style="height: 111px;background: #FFFFFF;width: 100%;margin-top: 10px;" class="border-F2F2F2">
			<div style="padding: 15px 140px;">
				<el-steps :active="active" :align-center="true" finish-status="success">
					<el-step :title="stepOneTitle==1?'商品详情':'购物车'"></el-step>
					<el-step title="填写订单"></el-step>
					<el-step title="支付订单"></el-step>
					<el-step title="支付结果"></el-step>
				</el-steps>
			</div>
		</div>
		<div class="" v-loading='orderLoading'>
			<div v-for="(item, index) in orderList" :key="index">
				<div class="font-size16 font-weight700 margin-b-10 margin-t-10 color-theme">
					订单{{index+1}}
				</div>
				<div class="border-F2F2F2">
					<!-- 收货人信息 -->
					<div>
						<div
							class="font-size14 font-weight700 margin-b-10 margin-t-10 margin-lr-15 flex-row-space-between-center">
							收货人信息</div>
						<div v-if="JSON.stringify(item.address) != '{}'">
							<div class="price-box active border-color-theme flex-row-space-between-center pointer box-sizing"
								style="background: #f8f9fe;" @mouseover="mouse = -99" @mouseout="mouse = -1">
								<div class="font-size14  flex-row-align-center">
									<div class=" flex-column-align-center margin-l-10 color-theme ">
										<div>{{ item.address.fLinkman }}</div>
										<div>{{ item.address.fLinkPhone }}</div>
									</div>
									<div class="margin-l-17 color-theme">工程名称：{{ item.address.fProjectName }}</div>
									<div class="margin-l-15 color-theme">收货地址：{{ item.address.fFullPathAddress }}</div>
								</div>
								<div class="padding-r-10 " v-show="mouse == -99">
									<i class="el-icon-edit font-color-666 font-size18 pointer"
										style="padding-right: 20px;" @click="EditAddress(item.address)"></i>
									<!-- <i class="el-icon-delete font-color-666 font-size18 pointer" @click="DeleteAddressChecked"></i> -->
								</div>
							</div>
							<!-- 上面这部分用来显示被选中的数据,下面的位其余数据 -->
							<div class="price-box active flex-row-space-between-center box-sizing pointer-border-color-theme border-F8F9FE"
								v-for="(addressitem, addressindex) in item.noDefaultAddress" :key="addressindex"
								style="background: #f8f9fe;" v-if="addressStatus && addressIndex == index"
								@mouseover="mouse = addressindex" @mouseout="mouse = -1">
								<div class="font-size14  flex-row-align-center font-color-999"
									@click="addressChoose(addressitem, index)">
									<div class=" flex-column-align-center margin-l-10 font-color-999">
										<div class="">{{ addressitem.fLinkman }}</div>
										<div class="">{{ addressitem.fLinkPhone }}</div>
									</div>
									<div class="margin-l-17 font-color-999">工程名称：{{ addressitem.fProjectName }}</div>
									<div class="margin-l-15 font-color-999">收货地址：{{ addressitem.fFullPathAddress }}
									</div>
								</div>
								<div class="padding-r-10" v-show="mouse == addressindex">
									<i class="el-icon-edit font-color-666 font-size18" style="padding-right: 20px;"
										@click="EditAddress(addressitem)"></i>
									<!-- <i class="el-icon-delete font-color-666 font-size18" @click="DeleteAddress(addressitem, addressindex)"></i> -->
								</div>
							</div>
							<div class="margin-lr-15 flex-row-align-center">
								<div class="pointer margin-r-10" @click="noDefaultAddressShow(index)">
									{{ addressStatus && addressIndex == index ? '收起地址' : '展开地址' }}
									<i class=" "
										:class="addressStatus && addressIndex == index ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
								</div>
								<span
									class="pointer color-theme border-color-theme padding-lr-10 font-size13 padding-tb-5"
									@click="addAddress">+新增收货地址</span>
							</div>
						</div>
						<div v-else>
							<div class="price-box active flex-row-space-between-center box-sizing padding-lr-10 font-color-999"
								style="background: #f8f9fe;">
								暂无可用收货地址
							</div>
							<div class="padding-lr-13 margin-t-15 margin-b-15">
								<span
									class="pointer color-theme border-color-theme padding-lr-10 font-size13 padding-tb-5"
									@click="addAddress">+新增收货地址</span>
							</div>
						</div>
					</div>
					<!-- 收货人信息结束 -->
					<div class="font-size14 font-weight700 margin-b-10 margin-t-10 margin-l-15">商品信息</div>
					<div class=" margin-lr-15">
						<div class="flex-row-space-between-center font-size14"
							style="height: 35px;background: #f8f9fe;">
							<p class="width756" style="text-align: center;">商品信息</p>
							<p class="width156" style="text-align: center;">数量</p>
							<p class="width156" style="text-align: center;">单价</p>
						</div>
						<div class="wrap">
							<div class=" ">
								<div class="">
									<div class="flex-row-space-between-center" style="height: 123px;">
										<div class="flex-row-start-start">
											<div class="flex-row-space-between-center" style="margin-left: 15px;">
												<div class="box-shadow-all imageRadius" style="">
													<el-image class="imageRadius" :src="item.fPicturePath"></el-image>
												</div>
											</div>
											<div style="margin-left: 19px;">
												<div class="">
													<div class="tag-box font-size12 font-color-fff radius2 margin-r-5"
														style="display: inline-block;line-height: 16px;"
														v-if="item.fShopTypeID == 1">{{ item.fShopTypeName }}</div>
													{{ item.fGoodsFullName }}
												</div>
												<div class="flex-row" style="margin-top: 8px;">
													<div class="tag-box-red  font-size12 " v-if="item.text">
														{{ item.text }}
													</div>
													<div class="tag-box-blue color-theme border-theme font-size12"
														v-if="item.dei">{{ item.dei }}</div>
												</div>
												<!-- <div>工程：达坂城第一工程</div> -->
											</div>
										</div>
										<div class=" ">
											<div class="flex-row">
												<div class="flexUD  width156"
													style="border-left: 1px solid #eaebef;height: 65px;">
													<el-input-number size="small" v-model="item.fPlanAmount"
														:min="item.fMinSaleAmount"
														:max="useGoodsInformation[0].fMaxBillAmount"
														:step="item.fAdjustmentRange" :precision="item.fPrecision"
														@change="valChange(item,index)" @blur="valBlur(item,index)"
														@focus="getInputFocus($event)"></el-input-number>
												</div>
												<div class="flexUD  width156"
													style="border-left: 1px solid #eaebef;height: 65px;">
													<p class="font-weight700">￥<span>
															</span>{{useGoodsInformation[0].fPrice}}
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div>
									<!--资金类型（只有企业用户，才可以看到资金类型的选择）-->
									<div class="padding-lr-15" style="padding-top: 6px;padding-bottom: 2px;">
										<el-row>
											<el-col :span="12">
												<div class="flex-row-align-center"
													style="min-width: 380px !important;  align-items: center;height:30px">
													资金类型：
													<el-radio-group v-model="item.fCapTypeID">
														<el-radio :label="item2.fCapTypeID"
															v-for="(item2, index2) of item.fCapList">
															{{item2.fCapType}}
														</el-radio>
													</el-radio-group>
												</div>
											</el-col>
										</el-row>
									</div>
									<div class="padding-lr-15" style="padding-top: 6px;padding-bottom: 2px;">
										<el-row>
											<el-col :span="11">
												<div class="flex-row-align-center"
													style="min-width: 380px !important;  align-items: center;height:30px;">
													<div style="display: flex;align-items: center;">
														<span>配送方式：</span>
														<el-tooltip placement="right">
															<div slot="content">
																1、自提：由卖家在每月扎账后为您开具已完成出厂金额的货款发票。<br />
																2、自营配送：由聚材在每月扎帐日后为您开具已完成出厂且配送的相应金额的货款发票。<br/>
																3、店铺配送：由第三方店铺为您开具相应已完成订单金额的货款发票。<br/>
																4、承运商代送：由聚材根据完成的出厂量按月为您开具相应的货款发票、提供物流服务的服务商开具运输发票。<br/>
																5、详细规则请参见《平台交易规则》、《物流服务协议》、《自营配送协议》。
															</div>
															<div
																class="form-label-mark flex-row-center-center margin-l-3">
																?</div>
														</el-tooltip>
													</div>
													<div style="display: flex;align-items: center;">
														<el-radio-group v-model="item.fDeliveryID">
															<el-radio :label="item2.fDeliveryID"
																v-for="(item2, index2) of item.deliveryDtos">
																{{item2.fDelivery}}
															</el-radio>
														</el-radio-group>
													</div>

												</div>
											</el-col>
										</el-row>
									</div>
									<div class="margin-lr-15" style="padding-top: 6px;padding-bottom: 7px;">
										<el-row :gutter="0">
											<el-col :span="7">
												<div class="flex-row-align-center">
													运输方式：
													<el-radio-group v-model="item.fBusinessTypeID">
														<el-radio :label="item7.fBusinessTypeID"
															v-for="(item7, index7) in item.BusinessType">
															{{item7.fBuTypeName}}
														</el-radio>
													</el-radio-group>
												</div>
											</el-col>
											
										</el-row>
									</div>	
								</div>
								<div style="padding-top: 8px;" class="divcss5-3"></div>
								<div style="height: 134px;">
									<div class="flex-column-start-end font-size12 " style="margin-right: 15px;line-height: 22px;">
										<div class="font-color-999 flex-row-space-between-center" style="width: 180px;">
											货款金额：
											<div><span>+¥{{ (useGoodsInformation[0].fPrice * 100 * item.fPlanAmount)/100 | NumFormat  }}</span></div>
										</div>
										<div class="font-color-999 flex-row-space-between-center" style="width: 180px;">
											运费金额：
											<div>  <span>+ ¥0.00</span></div>
										</div>
										<div class="font-color-999 flex-row-space-between-center" style="width: 180px;">
											优惠券抵扣：
											<div><span>- ¥0.00</span> </div>
										</div>
										<div class="font-color-999 flex-row-space-between-center" style="width: 180px;">
											积分抵扣：
											<div> <span>- ¥0.00</span></div>
										</div>
										<div class="flex-row-space-between-center" style="width: 180px;">
											<div>小计：</div>
											<div class="font-color-FF0000 font-weight700"> <span>¥{{ getOrderPrice(item)  | NumFormat}}</span></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 发票信息 -->
			<div class="flex-row">
				<div class="font-size14 font-weight700 margin-b-10 margin-t-10 margin-r-10">发票信息</div>
				<div class="flex-row-align-center border-box">
					开具货款发票：
					<el-radio v-model="fIsNeedInvoiceStatus" :label="1">需要</el-radio>
					<el-radio v-model="fIsNeedInvoiceStatus" :label="0">不需要</el-radio>
				</div>
			</div>

			<div class="border-F2F2F2">
				<div class="pointer flex-row-space-between-center color-theme padding-left-right-26 bg-fff padding-lr-5"
					style="background-color: #F2F5FF;height: 31px;border-radius: 1px;"
					v-if="invoiceInformation.feMail!=null" @click="invoiceInformationTipsShowClick">
					<div class="flex-row-align-center">
						<i class="el-icon-s-ticket font-size18"></i>
						<p class="font-size14" style="padding-left: 6px;">发票信息</p>
						<!-- <p class="font-size14">(发送至{{invoiceInformation.feMail}})</p> -->
					</div>
					<i class="el-icon-arrow-up font-size18 pointer"
						:class="invoiceInformation.invoiceInformationTipsShow?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
				</div>
				<div v-else class="flex-row-space-between-center color-theme padding-left-right-26 bg-fff padding-lr-5"
					style="background-color: #F2F5FF;height: 31px;border-radius: 1px;">
					<div class="flex-row-align-center"><a class="font-size14 pointer hover" style="padding-left: 6px;"
							@click="addInvoice('Add')">+维护发票收件地址</a></div>
				</div>

				<div class="font-size12 font-color-666 bg-fff" style="padding: 10px 21px;"
					v-if="invoiceInformation.invoiceInformationTipsShow">
					<div class="margin-b-5">
						<div class="flex-row line-height22">
							<div>发票类型：</div>
							<div>{{invoiceInformation.fInvoiceClassName}}</div>
						</div>
						<div class="flex-row line-height22">
							<div>发票抬头：</div>
							<div>{{invoiceInformation.fUnitName}}</div>
						</div>
						<div class="flex-row"
							:class="getUserInfo.fCommerceTypeID=='1'?'margin-b-10 padding-b-10 border-bottom-F2F2F2':'line-height22'">
							<div>{{getUserInfo.fCommerceTypeID=='1'?'身份证号：':'企业税号：'}}</div>
							<div>{{invoiceInformation.fTaxAccounts}}</div>
						</div>
						<div v-if="getUserInfo.fCommerceTypeID=='2'">
							<div class="flex-row line-height22">
								<div>住所：</div>
								<div>{{invoiceInformation.fAddress}}</div>
							</div>
							<div class="flex-row line-height22">
								<div>公司电话：</div>
								<div>{{invoiceInformation.fPhone}}</div>
							</div>
							<div class="flex-row line-height22">
								<div>开户银行：</div>
								<div>{{invoiceInformation.fBankNames}}</div>
							</div>
							<div class="flex-row margin-b-10 padding-b-10 border-bottom-F2F2F2">
								<div>银行账号：</div>
								<div>{{invoiceInformation.fBankAccounts}}</div>
							</div>
						</div>
						<div class="flex-row-space-between">
							<div>
								<div class="flex-row">
									<div>邮寄地址：</div>
									<div>{{invoiceInformation.fFullPathAddress}}</div>
								</div>
								<div class="flex-row line-height22">
									<div>收件人：</div>
									<div>{{invoiceInformation.fLinkman}} {{invoiceInformation.fLinkPhone}}</div>
								</div>
								<div class="flex-row">
									<div>邮箱：</div>
									<div>{{invoiceInformation.feMail}}</div>
								</div>
							</div>
						</div>
						<div class="  line-height22 pointer hover color-theme" @click="addInvoice('Edit')">
							修改发票收件地址
						</div>
					</div>
				</div>
			</div>
			<!-- 发票信息结束 -->
			<div class="flex-row-right bottomBox font-size16"
				style="line-height: 30px;margin-top: 15px;margin-bottom: 50px;">
				<div class="flex-row-center-center" style="margin-right: 30px;">
					<div class="flex-row-align-center">
						<div class="flex-row">
							<p class="font-color-FF0000 ">{{ orderList.length }}</p>
							<p class="font-color-999">件商品</p>
							<p class="font-color-000">，实付金额：</p>
							<p class="font-color-FF0000 font-weight700">¥{{ getOrderTotal() | NumFormat  }}</p>
						</div>
						<el-button style="width: 160px;height: 40px;margin: 15px;"
							class="background-color-theme pointer" :loading="createLoading"
							@click="createOrder">提交订单</el-button>
					</div>
				</div>
			</div>
		</div>
		<!-- 收货地址 -->
		<el-dialog v-if="addVisible" v-dialogDrag :close-on-press-escape="false" :title="pageTitle + '收货地址'"
			:visible.sync="addVisible" @close="handleClose" width="600px" :close-on-click-modal="false">
			<div ref="dialogBox" class="dialogScroll">
				<el-form ref="addressForm" :rules="rules" :model="addressForm" label-width="130px"
					label-position="right">
					<el-form-item label="收货人" prop="fLinkman">
						<el-input v-model="addressForm.fLinkman" placeholder="请输入收货人"></el-input>
					</el-form-item>
					<el-form-item label="手机号码" prop="fLinkPhone">
						<el-input v-model="addressForm.fLinkPhone" maxlength="11" placeholder="请输入手机号码"></el-input>
					</el-form-item>
					<el-form-item label="行政区域" class="areaBox" prop="area">
						<el-col :span="24">
							<selectArea v-model="addressForm.area"></selectArea>
						</el-col>
					</el-form-item>
					<el-form-item label="详细地址" prop="fAddress">
						<el-input v-model="addressForm.fAddress" placeholder="请输入详细地址"></el-input>
					</el-form-item>
					<el-form-item label="工程名称" prop="fProjectName">
						<el-input v-model="addressForm.fProjectName" placeholder="请输入工程名称"></el-input>
					</el-form-item>
					<el-form-item label="设置默认地址" prop="addressType">
						<el-switch v-model="addressForm.fIsDefault"></el-switch>
					</el-form-item>
					<el-form-item>
						<div id="container" style="height: 200px;" v-show="mapShow"></div>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="handleClose">取 消</el-button>
				<el-button size="small" type="primary" @click="submitForm('addressForm')">确 定</el-button>
			</span>
		</el-dialog>

		<!-- 维护发票信息 -->
		<el-dialog v-dialogDrag :close-on-press-escape="false" :title="invoiceType=='Add'?'维护':'编辑'+'发票收件地址'"
			:visible.sync="addInvoiceStatus" width="600px" :before-close="addInvoiceClose" :close-on-click-modal="false"
			class="invoice">
			<div class=" " style="">
				<div class="font-size12 font-color-666 bg-F2F4FD" style="margin-bottom: 5px;">
					<div class="invoice-box" style="line-height: 22px;padding: 10px 15px;">
						<div class="font-size14 font-weight700 font-color-000">
							{{getUserInfo.fCommerceTypeID=='1'?'个人：':'企业：'}}发票信息
						</div>
						<div class="flex-row">
							<div style="width: 70px;">发票类型：</div>
							<div>{{invoiceInformation.fInvoiceClassName}}</div>
						</div>
						<div class="flex-row">
							<div style="width: 70px;">发票抬头：</div>
							<div>{{invoiceInformation.fUnitName}}</div>
						</div>
						<div class="flex-row">
							<div style="width: 70px;">{{getUserInfo.fCommerceTypeID=='1'?'身份证号：':'企业税号：'}}</div>
							<div>{{invoiceInformation.fTaxAccounts}}</div>
						</div>
						<div v-if="getUserInfo.fCommerceTypeID=='2'">
							<div class="flex-row">
								<div style="width: 80px;">住所：</div>{{invoiceInformation.fAddress}}
							</div>
							<div class="flex-row">
								<div style="width: 70px;">公司电话：</div>
								<div>{{invoiceInformation.fPhone}}</div>
							</div>
							<div class="flex-row">
								<div style="width: 70px;">开户银行：</div>
								<div>{{invoiceInformation.fBankNames}}</div>
							</div>
							<div class="flex-row">
								<div style="width: 70px;">银行账号：</div>
								<div>{{invoiceInformation.fBankAccounts}}</div>
							</div>
						</div>
					</div>
				</div>
				<el-form ref="addInvoiceForm" :rules="rules" :model="addInvoiceForm" label-width="140px"
					label-position="right">
					<el-form-item label="收件邮箱" prop="feMail">
						<el-input v-model="addInvoiceForm.feMail" />
					</el-form-item>
					<el-form-item label="收件姓名" prop="fLinkman">
						<el-input v-model="addInvoiceForm.fLinkman" />
					</el-form-item>
					<el-form-item label="收件电话" prop="fLinkPhone">
						<el-input v-model.number="addInvoiceForm.fLinkPhone" maxlength="11" />
					</el-form-item>
					<el-form-item label="邮寄地址" prop="area">
						<div class="flex-row">
							<selectArea v-model="addInvoiceForm.area"></selectArea>
						</div>
					</el-form-item>
					<el-form-item label="详细地址" prop="fAddress">
						<el-input v-model="addInvoiceForm.fAddress" />
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="addInvoiceStatus = false">取 消</el-button>
				<el-button size="small" type="primary" @click="invoiceSubmitForm('addInvoiceForm')">确 定</el-button>
			</span>
		</el-dialog>
		<selectBillPay v-if='dialogVisible' :showModel="dialogVisible" @change="changeModel"></selectBillPay>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import breadcrumb from '@/components/breadcrumb.vue';
	import selectArea from '@/components/selectArea/selectArea.vue';
	import selectBillPay from './selectBillPay.vue'; // 票据协议的弹框
	export default {
		components: {
			breadcrumb,
			selectArea,
			selectBillPay,
		},
		data() {
			const rulesloginId = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入手机号'));
				} else {
					let phoneNumberRul =
						/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
					if (phoneNumberRul.test(value)) {
						callback();
					} else {
						callback(new Error('请输入正确的手机号码'));
					}
				}
			}
			return {
				index: -1,
				num: 0,
				radioIdSelects: this.$route.query.radioIdSelects ? JSON.parse(this.$route.query.radioIdSelects) : [],
				radioIdSelect: '',
				dialogVisible: false, //控制票据协议弹框的
				orderLoading: false,
				createLoading: false,
				laoding: false,
				orderList: [], //订单列表
				goodsOrder: [], //获取商品价格和概要信息需要的参数
				useGoodsInformation: [], //组织需要的参数
				invoiceInformation: {}, //发票信息
				chooseAddress: {}, //选中默认地址
				fGovernmenAreaID: ['65', '6501', '650104', '650104008'],
				addInvoiceStatus: false,
				addInvoiceForm: {
					feMail: "", // 收件邮箱
					fLinkman: '', //收件姓名
					fLinkPhone: '', //手机号码
					fAddress: "", //详细地址
					area: ['', '', '', '']
				},
				invoiceType: '', //发票收件信息新增还是编辑
				mouse: -1,

				addVisible: false,
				mapShow: false, //地图
				addr: '新疆乌鲁木齐市新市区经济开发区卫星路499号秦郡大厦B座501室',
				pageTitle: "新增",
				addressForm: {
					fLinkman: '',
					fLinkPhone: '',
					area: ['', '', '', ''],
					fAddress: '',
					fProjectName: '',
					feMail: '',
					fAddressTypeID: '1',
					addressType: '收货地址',
					fIsDefault: false
				},
				rules: {
					fLinkman: [{
							required: true,
							message: '请输入收货人',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 20,
							message: '长度在 2 到 20 个字符',
							trigger: 'blur'
						}
					],
					area: [{
						required: true,
						message: '请选择行政区域',
						trigger: 'blur'
					}],
					fLinkPhone: [{
							required: true,
							message: '请输入手机号码',
							trigger: 'change'
						},
						{
							validator: rulesloginId,
							trigger: 'blur'
						}
					],
					feMail: [{
							required: true,
							message: '请填写邮箱地址',
							trigger: 'blur'
						},
						{
							type: 'email',
							message: '请填写正确的邮箱地址',
							trigger: ['blur', 'change']
						}
					],
					fAddress: [{
						required: true,
						message: '请填写详细地址',
						trigger: 'blur'
					}],
					fProjectName: [{
						required: true,
						message: '请填写工程名称',
						trigger: 'blur'
					}]
				},
				fIsNeedInvoiceStatus: 1, //是否开具货款发票
				fIsNeedInvoiceStatusList: [{
						title: '是',
						id: 1
					},
					{
						title: '否',
						id: 0
					}
				],

				addressStatus: false, //除了默认地址以外其他地址是否显示
				addressIndex: 0, //除了默认地址以外显示哪一个商品的其他地址
				addressTol: [], // 收货地址列表
				active: 2,
				stepOneTitle: '', //订单第一步是什么
				// 收货地址是否维护 邮箱
				addressAddIsHaveEmail: false,
				// 提交数组
				submitList: [],
				// 已提交下标数组
				submitListIndex: 0,
				needPriceIndex: 0,
				// 提交成功数组
				submitSuccessList: [],
			};
		},
		async mounted() {
			console.log(this.radioIdSelects)
			this.fIsNeedInvoiceStatus = this.getUserInfo.fCommerceTypeID == '1' ? 0 :
				1; //fCommerceTypeID == '1'个人用户默认不开发票  fCommerceTypeID == '2'企业用户默认开发票
			this.stepOneTitle = this.$route.query.id; //id:1是商品详情跳转，2是购物车跳转，3是订单跳转
			let data = await this.$store.getters.getCommonData;
			data.forEach((item, index) => {
				let obj = {
					id: item.fGoodsID
				};
				let obj1 = {
					index: index,
					addressId: '', // 地址类型ID
					fGovernmenAreaID: -1, // 行政区域ID
					goodsId: item.fGoodsID, // 商品OD
					deliveryId: item.fDeliveryID, // 配送方式ID
					fShopUnitID: item.fShopUnitID, // 店铺ID
					fPlanAmount: item.fAmount, // 商品数量
					fCapTypeID: item.fCapTypeID,
					fMaxBillAmount: item.fMaxBillAmount,
					fPrice: item.fPrice
				};
				this.goodsOrder.push(obj);
				this.useGoodsInformation.push(obj1);
			});
			// 查询用户已启用收货地址
			this.getProjectList();

		},
		computed: {
			...mapGetters(['getThemeName', 'getUserInfo']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		methods: {
			//票据弹框返回数据方法
			changeModel(e) {
				this.dialogVisible = e
			},
			// 当前订单应支付金额
			getOrderPrice(item) {
				return this.useGoodsInformation[0].fPrice * item.fPlanAmount;
			},
			// 订单总额
			getOrderTotal() {
				let total = 0;
				this.orderList.forEach(item => {
					total = total + this.getOrderPrice(item)
				})
				return total
			},
			//获取地址列表
			getProjectList() {
				this.ApiRequestPost('api/mall/eb-customer/address/get-enable-list-by-current-user-address', {
					addressTypeId: 1, //地址类型 1：收货地址
					maxResultCount: 99,
					skipCount: 0
				}).then(
					result => {
						if (result.obj.items.length > 0) {
							// 收货地址
							this.addressTol = result.obj.items;

							// 默认地址
							let defaultaddress = {};
							let haveDefaultAddress = false;
							result.obj.items.forEach((item, index) => {
								// 斜杠去掉换为空格
								item.fFullPathAddress = item.fFullPathAddress.replace(/\\/g, '');
								// 是否默认地址
								if (item.fIsDefault == 1) {
									defaultaddress = item;
									haveDefaultAddress = true;
								}
							});
							if (haveDefaultAddress == true) {
								this.chooseAddress = defaultaddress;
							} else {
								this.chooseAddress = result.obj.items[0];
							}

							for (let item of this.useGoodsInformation) {
								item.addressId = this.chooseAddress.fAddressID;
								item.fGovernmenAreaID = this.chooseAddress.fGovernmenAreaID;
							}
						}
						this.getOrder();
					},
					rej => {}
				);
			},
			//获取订单
			async getOrder() {
				await this.ApiRequestPostNOMess('api/mall/goods/goods/get-batch', this.goodsOrder).then(
					res => {
						this.orderList = [];
						this.useGoodsInformation.forEach((item, index) => {
							res.obj.forEach((childitem, childindex) => {
								childitem.address = this.chooseAddress;
							});
							res.obj[item.index].noDefaultAddress = this.addressTol;
							res.obj[item.index].fPlanAmount = item.fPlanAmount; // 商品数量
							res.obj[item.index].fIsNeedFInvoiceStatus = 0; // 是否需要运输发票0不需要1需要
							res.obj[item.index].transportationInvoiceDtos = [{
									title: '需要',
									id: 1
								},
								{
									title: '不需要',
									id: 0
								}
							];
							res.obj[item.index].invoiceInformation = {};
							res.obj[item.index].BusinessType = [];
							res.obj[item.index].fBusinessTypeID = '1'; // 运输方式ID
							res.obj[item.index].isShowExpress = false
						});
						this.orderList = res.obj;
						// 获取运输方式 批量
						// this.getBusinessTypeBatch()
						// 获取发票信息
						this.getInvoiceInfo();
						// 根据商品ID查询当前商品配送方式  需要地址ID  承运商获取也需要地址ID
						this.getDeliveryByAreaid();
	
					},
					error => {}
				);
			},
			//获取发票信息
			async getInvoiceInfo() {
				this.getInvoiceAddress();
				await this.ApiRequestPostNOMess('api/mall/ebcustomer/invoice-info/get', {}).then(
					res => {
						if (res.obj.fApproveStatus != 254) {
							this.tipsInfo('发票未审核通过')
						}
						this.invoiceInformation.fUnitName = res.obj.fUnitName;
						this.invoiceInformation.fTaxAccounts = res.obj.fTaxAccounts;
						this.invoiceInformation.fAddress = res.obj.fAddress.replace(/\\/g, '');
						this.invoiceInformation.fPhone = res.obj.fPhone;
						this.invoiceInformation.fBankNames = res.obj.fBankNames;
						this.invoiceInformation.fBankAccounts = res.obj.fBankAccounts;
						this.invoiceInformation.fInvoiceClassName = res.obj.fInvoiceClassName;
					},
					error => {}
				);
			},
			//获取发票地址
			async getInvoiceAddress() {
				await this.ApiRequestPostNOMess('api/mall/eb-customer/address/get-invoice-address', {}).then(
					res => {
						if (res.obj.fFullPathAddress != null) {
							res.obj.area = ['', '', '', ''];
							res.obj.area[0] = res.obj.fGovernmenAreaID.substring(0, 2);
							res.obj.area[1] = res.obj.fGovernmenAreaID.substring(0, 4);
							res.obj.area[2] = res.obj.fGovernmenAreaID.substring(0, 6);
							res.obj.area[3] = res.obj.fGovernmenAreaID;
							this.addInvoiceForm = res.obj;
							this.invoiceInformation.feMail = res.obj.feMail;
							this.invoiceInformation.fLinkman = res.obj.fLinkman;
							this.invoiceInformation.fLinkPhone = res.obj.fLinkPhone;
							this.invoiceInformation.fFullPathAddress = res.obj.fFullPathAddress.replace(/\\/g, '');
						}
					},
					error => {}
				);
			},

			//发票信息显示
			invoiceInformationTipsShowClick() {
				this.invoiceInformation.invoiceInformationTipsShow = !this.invoiceInformation.invoiceInformationTipsShow;
				this.$forceUpdate();
			},


			//获取商品价格
			async getGoodsPrice(e) {
				let apiData = [];
				console.log(this.useGoodsInformation, '////////////')
				this.useGoodsInformation.forEach(item => {
					apiData.push({
						fGovernmenAreaID: item.fGovernmenAreaID, //不根据区域定价，区域id传-1
						goodsID: item.goodsId,
						deliveryId: item.deliveryId,
						capTypeID: item.fCapTypeID,
					})

				})
				await this.ApiRequestPostNOMess(
						'api/mall/ebsale/goods-price/get-list-goods-price-by-government-area-id-batch',
						apiData)
					.then(
						res => {
							res.obj.forEach((item, index) => {
								this.orderList.forEach((childitem, childindex) => {
									if (item.fGoodsId == childitem.fGoodsID) {
										childitem.fMinSaleAmount = item.fMinSaleAmount; // 最小销量
										childitem.fAdjustmentRange = item.fAdjustmentRange; // 销量调整幅度
										childitem.fPrecision = item.fPrecision; // 销量小数位数
									}
								});
							});
							this.$forceUpdate(); //解决动态该数据之后渲染数据慢
						},
						error => {}
					);
			},
			// 根据商品ID查询当前商品配送方式
			async getDeliveryByAreaid() {
				await this.useGoodsInformation.forEach((data, index) => {
					this.ApiRequestPostNOMess(
							'/api/mall/ebactivity/seckill-bill-goods-price/get-delivery-type-by-goodsId', {
								id: data.goodsId,
							})
						.then(
							res => {
								let BusinessList = []
								BusinessList.push({
									fBuTypeName: res.obj.fBuTypeName,
									fBusinessTypeID: res.obj.fBusinessTypeID
								})
								let deliverList = []
								deliverList.push({
									fDeliveryID: res.obj.fDeliveryID,
									fDelivery: res.obj.fDelivery
								})

								this.orderList[data.index].BusinessType = BusinessList;
								this.orderList[data.index].deliveryDtos = deliverList;
								this.orderList[index].fBusinessTypeID = BusinessList[0].fBusinessTypeID
								this.orderList[index].fDeliveryID = deliverList[0].fDeliveryID
								this.orderList[data.index].fCapList = [{
									fCapTypeID: 1,
									fCapType: "货币资金"
								}] //result.obj;
								this.orderList[index].fCapTypeID = this.orderList[data.index].fCapList[0]
									.fCapTypeID;
								this.useGoodsInformation[index].fCapTypeID = this.orderList[data.index]
									.fCapList.fCapTypeID
								this.getGoodsPrice();
								this.$forceUpdate(); //解决动态该数据之后渲染数据慢
							},
							error => {}
						);
				});
			},
			addInvoiceClose() {
				this.addInvoiceStatus = false;
				this.addInvoiceForm = {};
			},
			addInvoice(type) {
				this.invoiceType = type;
				if (type == 'Edit') {
					this.getInvoiceAddress();
				}
				this.addInvoiceStatus = true;
			},
			DeleteAddress(item, index) {
				for (var i = 0; i < this.addressTol.length; i++) {
					if (i == index) {
						this.addressTol.splice(index, index);
					}
				}
			},
			//展开关闭其他地址
			noDefaultAddressShow(index) {
				this.addressIndex = index;
				this.addressStatus = !this.addressStatus;
			},
			// 商品数量进步器 光标消失时调用
			valBlur(orderData, index) {
			},
			// 数量加减 变化时
			valChange(orderData, index) {

			},
			//计数器获得焦点全选内容
			getInputFocus(event) {
				event.currentTarget.select();
			},
			// 选择收货地址
			addressChoose(obj, activeindex) {
				this.chooseAddress = obj;
				this.orderList[activeindex].address = obj;
				this.orderList[activeindex].addressId = obj.fAddressID;
				this.orderList[activeindex].fGovernmenAreaID = obj.fGovernmenAreaID;
				this.useGoodsInformation[activeindex].fGovernmenAreaID = obj.fGovernmenAreaID;
				this.getDeliveryByAreaid();
			},
			//结算创建订单
			createOrder() {
				if (this.getOrderTotal() < 0) {
					this.$message('亲，您的订单金额有问题，请联系商城客服!');
					return
				}
				let findData = this.orderList.find(findItem => JSON.stringify(findItem.address) == '{}')
				if (findData) {
					// this.$message('请维护地址或者选择地址！');
					this.addAddress();
					return;
				}
				if (this.fIsNeedInvoiceStatus == 1 && this.invoiceInformation.feMail == null) {
					// this.$message('请维护发票收件地址');
					this.addInvoice('Add');
					return;
				}
				if (this.orderList.some(p => p.fDeliveryID == -1)) {
					this.$message('请选择配送方式');
					return;
				}
				if (this.orderList.some(p => p.fBusinessTypeID == 0 || p.fBusinessTypeID == '')) {
					this.$message('请选择运输方式');
					return;
				}
				//注意：只有企业用户，才可以谈资金类型的选择
				if (this.orderList.some(p => p.fCapTypeID == -1)) {
					this.$message('请选择资金类型');
					return;
				}
				var capItem = this.orderList.every((item1) => {
					return item1.fCapTypeID == this.orderList[0].fCapTypeID
				})
				if (capItem == false) {
					this.$message('请确认各个订单的资金类型保持一致！');
					return;
				}
				//如果有订单选择了 承兑汇票，就得去调接口查看客户是否开通了承兑汇票？
				if (this.orderList[0].fCapTypeID == 2) {
					let idimnSelectUrl = ''
					if (this.getUserInfo.fCommerceTypeID == 2) {
						//当客户的身份是 企业用户时
						idimnSelectUrl = 'api/mall/ebbalance/customer-acceptance/get-order-my-acceptance'
					} else {
						//当客户的身份是 个人用户时
						idimnSelectUrl = 'api/mall/ebbalance/customer-acceptance/get-personal-order-my-acceptance'
					}
					this.ApiRequestPostNOMess(idimnSelectUrl, {}).then(
						res => {
							console.log(res, '这个客户开没开！')
							//res.obj 有值证明人家开通着，否则证明根本没开通
							if (res.obj.length != 0) {
								this.dialogVisible = false
								// 提交参数
								this.submitList = [];
								// 提交成功数组
								this.submitSuccessList = [];
								this.orderList.forEach((item, index) => {
									// if (item.fCouponCustomerReceiveRecordID == '暂不使用' || item
									// 	.fCouponCustomerReceiveRecordID == '暂无可用') {
									// 	item.fCouponCustomerReceiveRecordID = -1;
									// }
									let params = {
										fGoodsID: item.fGoodsID,
										fAddressID: item.address.fAddressID,
										// fUsePoint: item.fUsePoint,
										fDeliveryID: item.fDeliveryID, // 配送方式ID
										fCapTypeID: item.fCapTypeID, //资金类型id(若为企业用户，那正常赋值；否则默认给1)
										fCarrierID: item.fCarrierID, // 承运商ID
										// fCouponCustomerReceiveRecordID: item
										// 	.fCouponCustomerReceiveRecordID == null ? -1 : item
										// 	.fCouponCustomerReceiveRecordID, // 优惠券ID
										fIsNeedFInvoiceStatus: item.fIsNeedFInvoiceStatus, // 运输发票
										fIsNeedInvoiceStatus: this.fIsNeedInvoiceStatus, // 必须要发票
										fPlanAmount: item.fPlanAmount, // 商品数量
										fAppTypeID: this.fAppTypeID, // 应用类型ID
										fBusinessTypeID: item.fBusinessTypeID, // 运输方式ID
									}
									this.submitList.push(params);
								});
								this.submitOrderList();
							} else {
								this.dialogVisible = true
							}
						},
						error => {}
					);
				} else {
					// 提交参数
					this.submitList = [];
					// 提交成功数组
					this.submitSuccessList = [];
					this.orderList.forEach((item, index) => {
						// if (item.fCouponCustomerReceiveRecordID == '暂不使用' || item.fCouponCustomerReceiveRecordID ==
						// 	'暂无可用') {
						// 	item.fCouponCustomerReceiveRecordID = -1;
						// }
						let params = {
							fGoodsID: item.fGoodsID,
							fAddressID: item.address.fAddressID,
							//fUsePoint: item.fUsePoint,
							fDeliveryID: item.fDeliveryID, // 配送方式ID
							fCapTypeID: item.fCapTypeID, //资金类型id(若为企业用户，那正常赋值；否则默认给1)
							fCarrierID: item.fCarrierID, // 承运商ID
							//fCouponCustomerReceiveRecordID: item.fCouponCustomerReceiveRecordID == null ? -1 :item.fCouponCustomerReceiveRecordID, // 优惠券ID
							fIsNeedFInvoiceStatus: item.fIsNeedFInvoiceStatus, // 运输发票
							fIsNeedInvoiceStatus: this.fIsNeedInvoiceStatus, // 必须要发票
							fPlanAmount: item.fPlanAmount, // 商品数量
							fAppTypeID: this.fAppTypeID, // 应用类型ID
							fBusinessTypeID: item.fBusinessTypeID, // 运输方式ID
						}
						this.submitList.push(params);
					});
					this.submitOrderList();
				}
			},
			// 异步【递归】提交订单方法
			async submitOrderList() {
				let forlength = this.submitList.length;
				let forIndex = JSON.parse(JSON.stringify(this.submitListIndex))
				// 提交订单是否成功
				let isSuccsss = true;
				this.createLoading = true;
				for (let index = forIndex; index < forlength; index++) {
					let item = this.submitList[index]
					// if (this.orderList[index].tableData.length === 0) {
					// 	item.fRuleID = -1
					// } else {
					// 	item.fRuleID = this.orderList[index].tableData[0].fRuleID
					// }
					console.log(item)
					// 异步调用API方法，以达到对成功的订单分段控制
					await this.ApiRequestPostNOMess('api/mall/ebsale/order/create-seckill-order', item)
						.then(
							res => {
								this.createLoading = false;
								// 记录已提交数据下标
								this.submitListIndex = index + 1;
								// res.message 为空时 视为提交成功。
								// res.message 不为空时 视为提交失败。
								if (res.message == "") {
									if (res.obj == null) {
										this.$message('');
										return;
									} else {
										this.submitSuccessList.push(res.obj)
									}
								} else {
									this.orderList.forEach((item, index) => {
										item.fCouponCustomerReceiveRecordID = "暂不使用";
									})
									isSuccsss = false;
									// 如果失败则提示用户是否继续
									res.message = res.message.split(';');
									let createTipCode = res.message[1];
									let createMessage = res.message[0];
									this.$confirm(createMessage + ', 是否继续?', '提示', {
										confirmButtonText: '确定',
										cancelButtonText: '取消',
										type: 'warning'
									}).then(() => {
										if (createTipCode == '001') {
											this.$store.dispatch("setShowAttestation", true);
										} else if (createTipCode == '002') {
											this.addInvoice('Add');
										} else if (createTipCode == '003') {
											if (this.getUserInfo.fCommerceTypeID == '1') {
												this.$router.push({
													path: '/businessme/individualUsersBindingCard',
													query: {
														title: '开通账服通',
														type: 2,
														fAccountname: '账服通'
													}
												}) //跳转个人用户绑卡
											} else if (this.getUserInfo.fCommerceTypeID == '2') {
												this.$router.push({
													path: '/businessme/enterpriseUsersBindingCard',
													query: {
														title: '开通账服通',
														type: 2,
														fAccountname: '账服通'
													}
												}) //跳转企业用户绑卡
											}
										} else if (createTipCode == '004') {
											this.submitListIndex = 0;
											this.submitList = []
											this.submitSuccessList = [];
										} else if (createTipCode == '005') {
											this.getCustomerPoint();
										} else if (createTipCode == '006') {
											this.submitListIndex = 0;
											this.submitList = []
											this.submitSuccessList = [];
										}
									}).catch(() => {
										// 取消则重置记录数据
										this.submitListIndex = 0;
										this.submitList = []
										this.submitSuccessList = [];
									});
								}
							},
							error => {
								isSuccsss = false;
								this.createLoading = false;
							}
						);
					// 提交订单失败则停止循环
					if (isSuccsss == false) {
						break;
					}
				}
				// 判断在当前方法结束时，订单是否已全部提交API以及是否有成功提交的订单
				if (this.submitListIndex == this.submitList.length && this.submitSuccessList.length > 0) {
					// 已全部提交API 且有成功提交的订单 跳转支付单
					this.jumpPayList();
				}
			},
			// 跳转支付单
			async jumpPayList() {
				// 组织订单ID数组
				let orderIds = []
				let fDeliveryIDList = []
				await this.submitSuccessList.forEach(item => {
					orderIds.push(item.fOrderID.toString())
					fDeliveryIDList.push(item.fDeliveryID)
				})
				// 跳转  replace :此方法跳转不会记录当前页面。
				sessionStorage.setItem('fDeliveryID', this.orderList[0].fDeliveryID);
				this.$router.replace({
					name: "PaymentOrder",
					params: {
						orderIds: orderIds,
						fBillTypeID: this.submitSuccessList[0].fBillTypeID,
						fDeliveryIDList: fDeliveryIDList
					}
				})
			},

			//新增地址
			addAddress() {
				this.pageTitle = "新增";
				this.addVisible = true;
			},
			//编辑地址
			EditAddress(item) {
				this.pageTitle = "编辑";
				this.ApiRequestPostNOMess("api/mall/eb-customer/address/get", {
						id: item.fAddressID
					})
					.then(res => {
						res.obj.fIsDefault = res.obj.fIsDefault == 0 ? false : true
						res.obj.fFullPathAddress = res.obj.fFullPathAddress.replace(/\\/g, '');
						res.obj.area = ['', '', '', ''];
						res.obj.area[0] = res.obj.fGovernmenAreaID.substring(0, 2);
						res.obj.area[1] = res.obj.fGovernmenAreaID.substring(0, 4);
						res.obj.area[2] = res.obj.fGovernmenAreaID.substring(0, 6);
						res.obj.area[3] = res.obj.fGovernmenAreaID;
						this.addressForm = res.obj;
						this.addVisible = true;
					}, error => {});
			},
			//新增或者编辑地址
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let areaN = this.addressForm.area;
						this.addressForm.fGovernmenAreaID = areaN[areaN.length - 1];
						if (this.pageTitle == "编辑") {
							this.addressForm.fIsDefault = this.addressForm.fIsDefault == true ? 1 : 0;
							this.addressForm.rowStatus = 16;
							this.ApiRequestPut('/api/mall/eb-customer/address/update', this.addressForm).then(
								result => {
									this.getProjectList();
									this.handleClose();
								},
								rej => {}
							);
						} else if (this.pageTitle == "新增") {
							this.ApiRequestPost('/api/mall/eb-customer/address/create', {
								fAddressTypeID: 1,
								fCustomerID: 0,
								feMail: this.addressForm.feMail,
								fGovernmenAreaID: this.addressForm.fGovernmenAreaID,
								fAddress: this.addressForm.fAddress,
								flng: 0,
								flat: 0,
								fLinkman: this.addressForm.fLinkman,
								fLinkPhone: this.addressForm.fLinkPhone,
								fRemark: "",
								fStatus: 1,
								fIsDefault: this.addressForm.fIsDefault == true ? 1 : 0,
								fProjectName: this.addressForm.fProjectName,
								fLastUseTime: "",
								fCreatorUserID: 0,
								rowStatus: 4
							}).then(
								result => {
									this.getProjectList();
									this.handleClose();
								},
								rej => {}
							);
						}
					} else {
						return false;
					}
				});
			},
			handleClose() {
				this.addVisible = false;
				this.resetForm();
			},
			//重置form表单
			resetForm() {
				this.addressForm = {
					fLinkman: '',
					fLinkPhone: '',
					area: [],
					fAddress: '',
					fProjectName: '',
					feMail: '',
					fAddressTypeID: '',
					fIsDefault: false
				}
			},

			//保存收件信息
			invoiceSubmitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.invoiceType == 'Add') {
							this.addInvoiceForm.fAddressID = 0;
						}
						/* this.addInvoiceForm.fGovernmenAreaID = this.addInvoiceForm.area[this.addInvoiceForm.area.length-1];
						console.log(this.addInvoiceForm); */
						this.ApiRequestPost('/api/mall/eb-customer/address/create-invoice-address', {
							fAddressID: this.addInvoiceForm.fAddressID,
							feMail: this.addInvoiceForm.feMail,
							fGovernmenAreaID: this.addInvoiceForm.area[this.addInvoiceForm.area.length -
								1],
							fAddress: this.addInvoiceForm.fAddress,
							fLinkman: this.addInvoiceForm.fLinkman,
							fLinkPhone: this.addInvoiceForm.fLinkPhone
						}).then(
							result => {
								// console.log(result);
								this.getInvoiceInfo();
								this.addInvoiceStatus = false
							},
							rej => {
								this.addInvoiceStatus = false
							}
						);
					} else {
						return false;
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.myMap {
		.el-dialog__body {
			padding: 0;

		}
	}

	.tit {
		display: inline-block;
		color: #FF0000
	}

	.btn {
		color: #0173FE;
		cursor: pointer;
		display: inline-block;
	}

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.pointer-border-color-theme:hover {
		cursor: pointer;

		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.pointer-color-theme:hover {
		cursor: pointer;

		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.border-bottom-theme {
		@include themify($themes) {
			border-bottom: 1px solid themed('themes_color');
		}
	}

	/* 面包屑CSS */
	// .el-tabs--card .el-tabs__header {
	// 	border-bottom: none;
	// }
	.breadcrumb {
		margin-top: 15px;
		background-color: white;
	}

	// .breadcrumb .el-breadcrumb {
	// 	width: 1225px;
	// 	line-height: 30px;
	// 	font-size: 16px;
	// 	margin: 0 auto;
	// }
	/* 面包屑CSS END */

	::v-deep .el-checkbox {
		padding: 0 10px !important;
	}

	// 购物车
	.wrap {
		width: 100%;
		// margin-bottom: 40px;
		// margin-top: 9px;
	}

	// 下方按钮
	/* 新的购物车样式 */

	.tag-box {
		padding: 0 3px;
		background-image: linear-gradient(to right top, #FF0000, #FF9999);
	}

	.tag-box-red {
		// margin: 0px !important;
		margin-right: 8px;
		padding: 0 4px;
		// height: 40px;
		line-height: 18px;
		color: #ffffff;
		background-image: linear-gradient(to right, #ff0000, #ff9999);
		// border-radius: 4px;
		font-weight: 400;
	}

	.tag-box-blue {
		// margin: 0px !important;
		padding: 0 5px;
		// height: 19px;
		line-height: 18px;
		color: #ffffff;
		font-weight: 400;
	}

	.margin-l-17 {
		margin-left: 17px;
	}

	.imageRadius {
		width: 65px;
		height: 65px;
		border-radius: 10px;
	}

	.width756 {
		width: 756px;
	}

	.width156 {
		width: 156px;
	}

	.bottomBox {
		height: 80px;
		background: #f8f9fe;
		width: 100%;
	}

	.flexUD {
		display: flex;
		//align-items: center; /*垂直居中*/
		justify-content: center;
		/*水平居中*/
	}

	/* 单选地址 */
	.price-box.active {
		width: 1070px;
		height: 50px;

		margin: 15px;
		position: relative;
		// transition: all 0.5s ease;
		// &::after {
		// 	content: '✔';
		// 	display: block;
		// 	height: 0px;
		// 	width: 0px;
		// 	position: absolute;
		// 	bottom: 0;
		// 	right: 0;
		// 	color: #fff;
		// 	font-size: 14px;
		// 	line-height: 18px;
		// 	border: 18px solid;
		// 	// border-color: transparent #55aaff #55aaff transparent;
		// 	@include themify($themes) {
		// 		border-color: transparent themed('themes_color') themed('themes_color') transparent;
		// 	}
		// }
	}

	.price-box.none {
		width: 1070px;
		height: 50px;
		margin: 15px;
		position: relative;
		// border: 2px solid #dfdfdf;
	}

	/* 步骤条 */
	::v-deep .el-step__line-inner {
		height: 10px;
		background-color: #00cc88;
	}

	::v-deep .el-step__icon.is-text {
		background: #00cc88;
		color: #fff;
		border: 5px solid;
	}

	::v-deep .el-step__icon {
		height: 50px;
		width: 50px;
	}

	::v-deep .el-step.is-horizontal .el-step__line {
		height: 10px;
		background: #e4e4e4;
		margin-top: 8px;
	}

	::v-deep .is-success {
		border-color: #dffff5;
		color: #00cc88;

		.el-step__icon.is-text {
			background: #00cc88;
			color: #dffff5;
		}
	}

	::v-deep .is-process {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}
	}

	::v-deep .is-wait {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}
	}

	::v-deep .el-step__title.is-process {
		font-weight: 400;
		// color: #999999;
	}

	::v-deep .el-step__title.is-wait {
		font-weight: 400;
		// color: #999999;
	}

	/* 步骤条结束 */

	.margin-l-15 {
		margin-left: 15px;
	}

	.margin-lr-15 {
		margin-left: 15px;
		margin-right: 15px;
	}

	/* 选择框 */
	::v-deep .el-checkbox {
		padding: 0 15px !important;
	}

	/* 下拉 */
	::v-deep .border-box .el-input__inner {
		border-radius: 0;
		width: 140px;
		// height: 32px;
		font-size: 14px;
		text-align: center;

		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	::v-deep .el-dialog .el-input__inner {
		width: 320px;
	}

	.inputStyle {
		border-radius: 0;
		width: 138px;
		height: 28px;
		font-size: 14px;
		text-align: center;

		@include themify($themes) {
			color: themed('themes_color');
		}

		border: 1px solid #f2f2f2;
	}

	/* 承运商 */
	.carrier {
		// width: 100%;
	}

	::v-deep .carrier .el-input__inner {
		width: 255px;
	}

	::v-deep .invoice .el-input__inner {
		width: 321px;
	}

	::v-deep .el-cascader {
		width: 321px;
	}

	/* 虚线 */
	.divcss5-3 {
		border-top: 2px dashed #f2f2f2;
		// height: 50px;
		width: 100%;
		margin-top: 10px;
	}

	.hover:hover {
		text-decoration: underline;

		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		@include themify($themes) {
			background-color: themed('themes_color');
			border-color: themed('themes_color');
		}
	}

	::v-deep .el-dialog__body {
		padding: 10px 20px 20px 20px;
	}

	.line-height22 {
		line-height: 22px;
	}

	.form-label-mark {
		width: 12px;
		height: 12px;
		border-radius: 50%;
		border: 1px solid #ccc;
		font-size: 12px;
		color: #ccc;
		cursor: pointer;
		margin-right: 10px;
	}

	.OrderText {
		line-height: 35px;
		color: #FF3333;
	}

	a:hover {
		border-bottom: 1px solid #0173FE;
	}
</style>